import React from 'react';

const IconLogo = () => (
  <svg
    width="707"
    height="612"
    viewBox="0 0 707 612"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.66223 612C4.81279 612 2.40722 607.832 4.3326 604.499L349.17 7.49573C351.095 4.16354 355.905 4.16352 357.83 7.49571L702.667 604.499C704.593 607.833 702.187 612 698.338 612H8.66223ZM640.027 578.35C643.877 578.35 646.282 574.182 644.357 570.849L357.83 74.7965C355.905 71.4644 351.095 71.4643 349.17 74.7965L62.6433 570.849C60.7179 574.182 63.1235 578.35 66.9729 578.35H640.027Z"
      fill="#FBBF24"
    />
    <path
      d="M400.39 325.177C400.39 325.177 415.807 349.261 420.766 371.104C420.766 371.104 430.432 410.65 413.764 434.842C400.399 454.174 406.609 430.972 406.609 430.972C411.316 410.227 418.156 373.66 400.39 325.177Z"
      fill="#FBBF24"
    />
    <path
      d="M391.615 369.259C390.31 345.958 386.125 318.013 376.882 285.091C376.882 285.091 390.571 303.955 399.508 340.891C404.368 360.907 405.25 381.796 403.738 402.496L403.27 408.139C401.65 425.329 398.194 444.913 390.886 453.274L390.832 453.328C387.907 456.721 382.885 453.382 384.091 448.891C387.853 435.094 393.91 408.967 391.615 369.259Z"
      fill="#FBBF24"
    />
    <path
      d="M353.995 241C353.995 241 358.072 289.015 360.322 343.087C360.736 353.068 361.042 363.202 361.312 373.237C361.743 389.62 361.812 406.01 361.519 422.395C361.204 437.551 360.421 451.348 359.062 462.58C358.279 469.105 349.819 469.105 349.036 462.58C347.056 446.119 346.21 425.482 346.156 403.486C346.156 391.111 346.318 378.304 346.678 365.551C346.939 356.776 347.263 348.055 347.623 339.478C349.873 286.612 353.995 241 353.995 241Z"
      fill="#FBBF24"
    />
    <path
      d="M296.314 377.521C295.64 387.767 295.921 398.053 297.151 408.247V408.238C298.106 415.881 299.555 423.454 301.489 430.909C307.708 454.687 294.334 434.779 294.334 434.779C291.648 430.367 289.588 425.602 288.214 420.622C285.496 411.109 284.821 401.029 285.037 392.515C285.343 380.239 287.377 371.041 287.377 371.041C288.169 367.54 289.204 363.94 290.464 360.43C296.836 342.097 307.708 325.114 307.708 325.114C304.306 334.411 301.804 343.24 299.977 351.655C297.988 360.853 296.836 369.475 296.314 377.521Z"
      fill="#FBBF24"
    />
    <path
      d="M316.744 365.29C315.96 376.036 315.786 386.817 316.222 397.582C317.212 421.774 321.235 438.703 323.962 448.891C325.204 453.328 320.137 456.676 317.212 453.328L317.158 453.274C309.796 444.913 306.403 425.329 304.783 408.139L304.315 402.442C303.577 392.038 303.415 381.598 304.045 371.248C304.567 362.995 305.611 354.841 307.231 346.795L307.286 346.541C307.683 344.686 308.089 342.784 308.545 340.891C317.473 303.955 331.225 285.091 331.225 285.091C325.42 305.89 321.595 324.646 319.255 341.575C318.125 349.446 317.287 357.357 316.744 365.29Z"
      fill="#FBBF24"
    />
    <path
      d="M338.893 363.67C338.706 375.652 338.76 387.636 339.055 399.616H339.046C339.468 418.812 340.639 437.985 342.556 457.09C342.97 461.635 338.218 464.407 335.131 461.482L335.077 461.419C334.663 461.059 334.357 460.591 334.087 460.069C325.933 442.825 323.746 416.491 323.323 396.745C323.017 381.382 323.8 369.997 323.8 369.997L324.268 363.625C324.898 355.048 325.735 347.11 326.617 339.748C333.205 285.928 347.623 256.093 347.623 256.093C344.698 283.678 340.468 316.231 339.577 338.749C339.208 347.47 339.001 355.786 338.893 363.67Z"
      fill="#FBBF24"
    />
    <path
      d="M365.443 457.09C366.487 447.217 368.26 427.624 368.935 399.616C369.23 387.639 369.284 375.658 369.097 363.679C368.989 355.786 368.782 347.479 368.413 338.749C367.531 316.231 363.301 283.678 360.367 256.093C360.367 256.093 374.794 285.928 381.382 339.748C382.35 347.687 383.131 355.649 383.722 363.625L384.199 369.997C384.199 369.997 384.982 381.382 384.667 396.745C384.253 416.491 382.057 442.825 373.903 460.069C373.665 460.582 373.329 461.044 372.913 461.428L372.859 461.482C369.772 464.407 365.02 461.635 365.443 457.09Z"
      fill="#FBBF24"
    />
  </svg>
);

export default IconLogo;
